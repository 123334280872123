import * as SingleSpa from 'single-spa';
import { fulfillsAnyPredicate } from '../utilities/activity-functions';
import { FeatureToggleIdentity, FeatureToggles } from '@catalystone/feature-toggles-client-js';
import { environmentService } from '../environment.service';
import { stateService } from '../../application';
 
class RoutingService {
  private featureTogglesClient;
  private featureTogglesInitialized= false;
 
  constructor() {
  }
 
  bindEvents() {
    window.addEventListener('single-spa:routing-event', (event) => this.decideIfApplicationExists(event), false);
    window.addEventListener('single-spa:before-first-mount', this.firstRedirect, false);
  }
 
  private async decideIfApplicationExists(event: Event): Promise<void> {
    const loc = (event.target as Window).location;
 
    if (fulfillsAnyPredicate(loc)) {
      return;
    }
 
    await this.checkAndNavigateToOneToOneDashboard(loc);
  }
 
  private firstRedirect(event: Event): void {}
 
  private async checkAndNavigateToOneToOneDashboard(loc: Location): Promise<void> {
    if (loc.href.includes('one-to-one-app')) {
      const isFeatureEnabled = await this.isFeatureEnabled('angularreactSupport');
 
      if (isFeatureEnabled) {
        const updatedUrl = loc.href.replace('one-to-one-app', 'one-to-one-angular');
  
        SingleSpa.navigateToUrl(updatedUrl);
      }
    }
  }
 
  private async isFeatureEnabled(featureName: string): Promise<boolean> {
    if (!this.featureTogglesClient) {
      await this.initializeFeatureToggles();
    }
 
    if (!this.featureTogglesClient) {
      return false;
    }
 
    return this.featureTogglesClient.isFeatureEnabled(
      featureName,
      new FeatureToggleIdentity(undefined, undefined, undefined, this.tenantId, this.tenantName)
    );
  }

  private async initializeFeatureToggles(): Promise<void> {
    if (this.featureTogglesInitialized) {
      return;
    }
 
    FeatureToggles.init(`${environmentService.env.apiUrl}feature-toggles`);
    this.featureTogglesClient = await FeatureToggles.getClient('one-to-one');
    this.featureTogglesInitialized = true;
  }
 
  private get tenantName(): string {
    const hostName = window.location.hostname.split('.catalystone')[0];
    return hostName.split('.')[0];
  }
 
  private get tenantId(): string {
    return stateService.getSnapshot().auth?.token.getTenantId();
  }
}
 
export const routingService = new RoutingService();
