import * as SingleSpa from 'single-spa';
import { configureSystemJSDependencies, registerSystemJSImportMap } from '../../system';
import { loadingService, stateService, telemetryService } from '../application';
import { utilities } from '../communication/utilities';
import { environmentService } from './environment.service';
import { microAppRegistryService } from './micro-app-registry.service';
import { BundleConfig, Environment, MicroApp } from './models';
import {
  AmplitudeReturn,
  bundleConfigService,
  languageService,
  messageService,
  routingService,
  tenantConfigService,
  userAnalyticsService
} from './services';

import { AxiosConfigurationService } from '../../system/axios.configuration';
import { createDevTools } from './utilities/devtools';

import { ThemeContext } from '@catalystone/react-core/theme';

import { FeatureToggleIdentity, FeatureToggles } from '@catalystone/feature-toggles-client-js';
import { AppProps } from '@catalystone/react-core/spa-types';
import { isNil } from '../utils';

class MicroFrontEnd {
  private _currentLocale: string;
  public microApps: Map<string, MicroApp>;

  private initTelemetry(env: Environment) {
    const [, origin] = env.apiUrl.match(/(?:https?:\/\/)([\w\.]*)\/?/);

    telemetryService.initialize({
      instrumentationKey: env.appInsightsKey,
      ...(env.appInsightsConfig || {}),
      correlationHeaderDomains: [origin]
    });
  }

  private initDevTools(env: Environment) {
    if (env.devtools) {
      createDevTools([...this.microApps.keys()]);
    }
  }

  private setBrowserTitle(title?: string) {
    if (title) {
      document.title = title;
    }
  }

  init = async (): Promise<boolean> => {
    const microFrontendServicesPromise = this.initMicroFrontEndServices();
    const langPromise = languageService.getLocaleToLoad();
    if (await this.handleIEGracefully(langPromise)) {
      return false;
    }
    const userAnalyticsPromise = this.initUserAnalytics(microFrontendServicesPromise);
    const bundleConfigPromise = this.loadAppBundles(langPromise, userAnalyticsPromise);
    const titlePromise = this.loadBrowserTitle();

    const loaderPromise = Promise.all([bundleConfigPromise, titlePromise]);
    return loaderPromise.then(async () => {
      return true;
    });
  };

  private handleIEGracefully(langPromise: Promise<string>): Promise<boolean> {
    return (async () => {
      if (!this.isIE()) {
        return false;
      }
      try {
        const [locale, customerName] = await Promise.all([langPromise, tenantConfigService.fetchTitleWithoutCOName()]);
        if (window.location.pathname !== '/no-ie-support.html') {
          let redirectHREF = `/no-ie-support.html?locale=${locale}`;
          redirectHREF += customerName ? `&customer=${encodeURIComponent(customerName)}` : '';
          window.location.href = redirectHREF;
        }
        return true;
      } catch (error) {
        console.warn(error);
        telemetryService.logException(new Error(`Failed handling IE compatibility gracefully: ${error}`));
        return false;
      }
    })();
  }

  private async initUserAnalytics(
    envPromise: Promise<Environment | void>
  ): Promise<[PromiseSettledResult<AmplitudeReturn>]> {
    await envPromise;
    return userAnalyticsService.initialize();
  }

  async updateLocale(locale: string): Promise<void> {
    const globalLocale = stateService.getSnapshot().globalLocale;
    const userLocale = stateService.getSnapshot().userLocale;
    if (languageService.globalLocale && languageService.globalLocale !== globalLocale) {
      stateService.set({
        globalLocale: languageService.globalLocale
      });
    }

    if (languageService.userLocale && languageService.userLocale !== userLocale) {
      stateService.set({
        userLocale: languageService.userLocale
      });
    }

    if (this._currentLocale !== locale) {
      this._currentLocale = locale;
      const bundleConfig = await bundleConfigService.updateLocale(locale);
      this.unloadMicroApps(bundleConfig);
    }
  }

  private redirect(): void {
    const redirectURL = this.getRedirectURL();
    const bypassRedirection = this.bypassRedirection();

    let baseURL = 'portals';

    if (bypassRedirection) {
      baseURL = `${location.pathname}${location.search}`;
    }

    if (redirectURL) {
      SingleSpa.navigateToUrl(
        `${baseURL}${bypassRedirection && location.search ? '&' : '?'}redirectURL=${redirectURL}`
      );
    } else {
      SingleSpa.navigateToUrl(`${baseURL}`);
    }
  }

  private bypassRedirection(): boolean {
    const searchParams = new URLSearchParams(location.search);
    const isAccessDeniedAndBypassRedirection = searchParams.get('type') === '3';
    return (
      (location.pathname.startsWith('/portals/login') && !!this.getLocationQueryParamValue('unlockkey')) ||
      (location.pathname.startsWith('/portals/new-user-page') && !!this.getLocationQueryParamValue('username')) ||
      location.pathname.startsWith('/portals/logout') ||
      location.pathname.startsWith('/portals/set-password') ||
      location.pathname.startsWith('/portals/disclaimer') ||
      location.pathname.startsWith('/portals/forgot-password') ||
      location.pathname.startsWith('/portals/profile-login') ||
      location.pathname.startsWith('/portals/deputy-login') ||
      (location.pathname.startsWith('/portals/access-denied') && isAccessDeniedAndBypassRedirection) ||
      location.pathname.startsWith('/document-previewer')
    );
  }

  private getLocationQueryParamValue(queryParamKey: string): string {
    const searchResult = location.search.match(`${queryParamKey}=([^&]*)`);
    if (searchResult) {
      return searchResult[1];
    } else {
      return '';
    }
  }

  start(bundleConfig: BundleConfig[]): void {
    routingService.bindEvents();
    messageService.bindEvents();
    utilities.init();
    SingleSpa.start();

    // register import map for proper load of SystemJS modules
    registerSystemJSImportMap(bundleConfig);

    configureSystemJSDependencies().then(() => {
      this.registerMicroApps(bundleConfig);
      // first time route to portal with redirect URL
      this.redirect();
      languageService.subscribeToUserLocale().subscribe((locale: string) => {
        this.updateLocale(locale);
      });
    });
  }

  private registerMicroApps(bundleConfig: BundleConfig[]): void {
    bundleConfig
      .filter((bundle) => !isNil(bundle) && this.microApps.has(bundle.name))
      .forEach((bundle) => {
        const microApp = this.microApps.get(bundle.name);

        if (microApp.url !== bundle.url) {
          microApp.url = bundle.url;

          this.registerMicroApp(microApp, bundle.locale, bundle.isSystemJS); // register as UMD or SystemJS
        }
      });
  }

  private unloadMicroApps(bundleConfig: BundleConfig[]): void {
    bundleConfig
      .filter((bundle) => !isNil(bundle) && !bundle.isSystemJS && this.microApps.has(bundle.name)) // skip if empty or systemjs is true
      .forEach((bundle) => {
        const microApp = this.microApps.get(bundle.name);

        if (microApp.url !== bundle.url) {
          // replace angular bandle url with proper locale
          microApp.url = bundle.url;
          // unload previews bundle
          SingleSpa.unloadApplication(bundle.name);
        }
      });
  }

  private registerMicroApp(microApp: MicroApp, locale: string, isSystemJS = false): void {
    const { name, url, activityFunction, version } = microApp;

    // define import function, based on micro app bundle type
    const importFunction = isSystemJS
      ? async ({ name }: { name: string }) => System.import(name)
      : async ({ name }: { name: string }) => {
          await microApp.load();

          return window[name];
        };

    SingleSpa.registerApplication<AppProps>(name, importFunction, activityFunction, {
      version,
      locale,
      telemetrySamplingRate: 25,
      telemetryKey: environmentService.env.appInsightsKey,
      publicUrl: url,
      environment: environmentService.env,
      axiosConfiguration: AxiosConfigurationService.getInstance(),
      theme: ThemeContext.getInstance(environmentService.env.cdnUrl)
    });
  }

  private getRedirectURL(): string {
    if (
      location.pathname === '/' ||
      location.pathname.startsWith('/portals') ||
      location.pathname.startsWith('/document-previewer')
    ) {
      return '';
    } else {
      return encodeURIComponent(location.pathname + location.search);
    }
  }

  private async initMicroFrontEndServices(): Promise<void | Environment> {
    stateService.init();
    loadingService.init();
    this.microApps = await microAppRegistryService.init();

    return environmentService.init().then((env) => {
      this.initDevTools(env);
      this.initTelemetry(env);
      // TODO: This is a temporary fix not to call FeatureToggle for prod as below was an experiment for New Navigation feature.
      // this.isFeatureEnabled('POC_NEW_NAVIGATION')
      //   .then((isEnabled) => {
      //     if (isEnabled) {
      //       const headerRow = document.querySelector('#app-header-row');
      //       headerRow?.classList.add('d-none');
      //     } else {
      //       const newNavigation = document.querySelector('#app-new-navigation');
      //       newNavigation?.classList.add('d-none');
      //     }
      //   })
      //   .catch((error) => {
      //     console.error('An error occurred while checking if the feature is enabled:', error);
      //   });
    });
  }

  private loadAppBundles(
    langPromise: Promise<string>,
    userAnalyticsPromise: Promise<[PromiseSettledResult<AmplitudeReturn>]>
  ): Promise<BundleConfig[] | void> {
    return Promise.allSettled([langPromise, userAnalyticsPromise])
      .then(async (promiseValues: any) => {
        this._currentLocale = promiseValues[0]?.value;
        // Bundle config has dependency of locale and env both, hence calling it after both are resolved.
        return bundleConfigService.getConfig(this._currentLocale);
      })
      .then((config) => {
        this.start(config);
      });
  }

  private loadBrowserTitle(): Promise<void | string> {
    return tenantConfigService.browserTitleWithCOName().then((title) => {
      this.setBrowserTitle(title);
    });
  }

  private isIE(): boolean {
    return /(msie|trident)\/(\d+(\.?_?\d+)+)/i.test(window.navigator.userAgent);
  }

  private async isFeatureEnabled(featureName: string): Promise<boolean> {
    FeatureToggles.init(`${environmentService.env.apiUrl}feature-toggles`);
    const client = await FeatureToggles.getClient('pixels');
    return client.isFeatureEnabled(
      featureName,
      new FeatureToggleIdentity(undefined, undefined, undefined, this.tenantId, this.tenantName)
    );
  }

  private get tenantName(): string {
    const hostName = window.location.hostname.split('.catalystone')[0];
    return hostName.split('.')[0];
  }

  private get tenantId(): string {
    return stateService.getSnapshot().auth?.token.getTenantId();
  }
}

export const microFrontEnd = new MicroFrontEnd();
window['microFrontEnd'] = microFrontEnd;
